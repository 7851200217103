const Exposicao = () => {
	return (
		<>
			<div className='row p-5'>
				<h1 className='display-5'>Fotos da Exposição</h1>
				<div className='lead'>
					Imagens do makin-of, preparativos, dia a dia da exposição
				</div>
			</div>
		</>
	);
};

export default Exposicao;
