import pmic from "../../_assets/logos_parceiros/pmic.png";
import adeviudi from "../../_assets/logos_parceiros/adeviudi.png";
import pertencer from "../../_assets/logos_parceiros/pertencer.png";
import arco from "../../_assets/logos_parceiros/arco.png";
import fundacao from "../../_assets/logos_parceiros/fundacao-educativa.png";
import tvu from "../../_assets/logos_parceiros/tv-univ.png";
import cintesp from "../../_assets/logos_parceiros/cintesp.png";
import tac from "../../_assets/logos_parceiros/tac.png";
import radio from "../../_assets/logos_parceiros/logoradio.png";
const ReguaLogos = () => {
	return (
		<div className='regua-logos'>
			<img className='img-fluid' src={pmic} />
			<img className='img-fluid' src={adeviudi} />
			<img className='img-fluid' src={arco} />
			<img className='img-fluid' src={radio} />
			<img className='img-fluid' src={tvu} />
			<img className='img-fluid' src={fundacao} />
			<img className='img-fluid' src={pertencer} />
			<img className='img-fluid' src={tac} />
			<img className='img-fluid' src={cintesp} />
		</div>
	);
};
export default ReguaLogos;
