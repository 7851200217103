import oficina from "../../_assets/oficina.jpeg";
const Ofinica = () => {
	return (
		<>
			<div className='row p-5'>
				<h1 className='display-5 my-3'>Oficina de Livros Táteis</h1>
				<img className='img-fluid' src={oficina} alt='' />
			</div>
		</>
	);
};

export default Ofinica;
