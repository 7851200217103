import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import { Outlet } from "react-router-dom";

function App() {
	return (
		<>
			<ScrollToTop />
			<Layout>
				<Outlet />
			</Layout>
		</>
	);
}

export default App;
