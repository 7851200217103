import { Link } from "react-router-dom";

const NavigationItem = (props) => {
	return (
		<>
			<Link to={props.url} className='link'>
				<li className='nav-item'>
					<div className='navigation-item'>
						{props.label.toUpperCase()}
					</div>
				</li>
			</Link>
		</>
	);
};
export default NavigationItem;
