import ReguaLogos from "../ReguaLogos";

const Footer = () => {
	return (
		<div className='footer'>
			<ReguaLogos />
		</div>
	);
};
export default Footer;
