import { ContainerObras } from "../../components/ContainerObras";
import ItemObra from "../../components/ItemObra";
import { obras } from "../../data/obras";
import maquina from "../../_assets/obrasCapa/maquina.png";

const Home = () => {
	return (
		<>
			<div className='row p-5'>
				<p className='lead'>
					<b> A Exposição Braille de Ontem</b> é uma iniciativa que
					visa apresentar ao público de Uberlândia e região que os
					esforços por disponibilizar material em Braille para as
					pessoas com deficiência visual na cidade já têm história!{" "}
					Esta exposição é permanente e estará disponível ao público
					na <b> ADEVIUDI</b>, instituição que desde a década de 1970
					oferece biblioteca com material em Braille, além de aulas e
					outras atividades para as pessoas com deficiência visual e
					seus familiares.
					<br /> O acervo da instituição continha alguns livros
					antigos em Braille e uma máquina de escrever Braille bem
					antiga, o que despertou na proponente deste projeto a
					intenção de criar uma movimentação de conhecimento não só em
					torno do Braille e de tudo que ele vem representando na
					história de muitas pessoas, como também em torno da
					biblioteca da instituição e da forma acessível como se
					oferece informações a pessoas com deficiência visual. O
					acervo desta exposição conta com obras literárias e
					informacionais que deram início à biblioteca da Adeviudi
				</p>
			</div>
			<div className='px-5'>
				<h1 className='display-6'>Hobras da Exposição</h1>
			</div>
			<ContainerObras>
				{obras.map((obra) => {
					return <ItemObra obra={obra} />;
				})}

				<div className='card-obra'>
					<img
						src={maquina}
						alt={"MÁQUINA DE ESCRITA BRAILLE"}
						width={"200px"}
					/>
					<div className='card-description'>
						<h6 className='lead text-start'>
							<b>MÁQUINA DE ESCRITA BRAILLE</b>
						</h6>
						<div className='card-info'>
							<div className='lead'>Tatrapoint</div>
							<div className='lead' style={{ color: "yellow" }}>
								<b></b>
							</div>
						</div>
						<a
							target='blank'
							href='#'
							className='btn btn-outline-light'>
							Baixar PDF
						</a>
					</div>
				</div>
			</ContainerObras>
		</>
	);
};

export default Home;
