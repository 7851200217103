import styled, { css } from "styled-components";

export const ContainerObras = styled.div`
	${() => css`
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
		@media (max-width: 760px) {
			grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		}
		padding: 20px;
		gap: 20px;
	`}
`;
