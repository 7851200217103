import Footer from "../Footer";
import Header from "../Header";
import insta from "../../_assets/icoInsta.webp";
import whats from "../../_assets/whats.png";
import { alfabeto } from "../../data/alfabeto";

const Layout = (props) => {
	return (
		<>
			<Header />
			<div className='container-fluid'>
				<div className='row bg-wood body-content'>
					<div className='col-md-2 d-none d-md-block py-3'>
						<div className='container-alfabeto'>
							{alfabeto.map((letra) => {
								return (
									<img
										src={letra.img}
										width={"60px"}
										alt=''
										srcset=''
									/>
								);
							})}
						</div>
					</div>
					<div className='col-md-9 p-0 transparent-background'>
						<div className='pages-content'>{props.children}</div>
						<div className='nota-rodape p-3 '>
							<hr
								style={{
									color: "#ffffff",
									height: 5,
								}}
							/>
							<p className='lead text-center'>
								Digitalização de Livros Antigos | Exposição
								Permanente | Livro Tátil Sensorial | Oficinas de
								Aprendizado <br />
								Venha conhecer e se inspirar com a rica hstória
								do Braille em nossa cidade!
							</p>
							<p className='lead text-center'>
								#BrailleDeOntem #Acessibilidade
								#CulturaEmBraille #Uberlândia
							</p>
							<div className='d-flex justify-content-center align-items-center'>
								<a
									href='https://www.instagram.com/brailledeontem/'
									target='blank'>
									<img src={insta} width={"50px"} />
								</a>
								<a href='#' target='blank'>
									<img src={whats} width={"70px"} />
								</a>
							</div>
						</div>
					</div>
					<div className='col-md-2 d-block d-md-none'>
						<div className='container-alfabeto'>
							{alfabeto.map((letra) => {
								return (
									<img
										src={letra.img}
										alt=''
										width={"60px"}
										srcset=''
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
export default Layout;
