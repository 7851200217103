import a from "../_assets/alfabeto/a.png";
import b from "../_assets/alfabeto/b.png";
import c from "../_assets/alfabeto/c.png";
import d from "../_assets/alfabeto/d.png";
import e from "../_assets/alfabeto/e.png";
import f from "../_assets/alfabeto/f.png";
import g from "../_assets/alfabeto/g.png";
import h from "../_assets/alfabeto/h.png";
import i from "../_assets/alfabeto/i.png";
import j from "../_assets/alfabeto/j.png";
import k from "../_assets/alfabeto/k.png";
import l from "../_assets/alfabeto/l.png";
import m from "../_assets/alfabeto/m.png";
import n from "../_assets/alfabeto/n.png";
import o from "../_assets/alfabeto/o.png";
import p from "../_assets/alfabeto/p.png";
import q from "../_assets/alfabeto/q.png";
import r from "../_assets/alfabeto/r.png";
import s from "../_assets/alfabeto/s.png";
import t from "../_assets/alfabeto/t.png";
import u from "../_assets/alfabeto/u.png";
import v from "../_assets/alfabeto/v.png";
import x from "../_assets/alfabeto/x.png";
import y from "../_assets/alfabeto/y.png";
import w from "../_assets/alfabeto/w.png";
import z from "../_assets/alfabeto/z.png";
const alfabeto = [
	{
		id: 1,
		img: a,
	},
	{
		id: 2,
		img: b,
	},
	{
		id: 3,
		img: c,
	},
	{
		id: 4,
		img: d,
	},
	{
		id: 5,
		img: e,
	},
	{
		id: 6,
		img: f,
	},
	{
		id: 7,
		img: g,
	},
	{
		id: 8,
		img: h,
	},
	{
		id: 9,
		img: i,
	},
	{
		id: 10,
		img: j,
	},
	{
		id: 11,
		img: k,
	},
	{
		id: 12,
		img: l,
	},
	{
		id: 13,
		img: m,
	},
	{
		id: 14,
		img: n,
	},
	{
		id: 17,
		img: o,
	},
	{
		id: 18,
		img: p,
	},
	{
		id: 18,
		img: q,
	},
	{
		id: 20,
		img: r,
	},
	{
		id: 21,
		img: s,
	},
	{
		id: 22,
		img: t,
	},
	{
		id: 23,
		img: u,
	},
	{
		id: 24,
		img: v,
	},
	{
		id: 25,
		img: x,
	},
	{
		id: 26,
		img: y,
	},
	{
		id: 27,
		img: w,
	},
	{
		id: 28,
		img: z,
	},
];
export { alfabeto };
