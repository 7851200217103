import memoriasPostumas from "../_assets/obrasCapa/memorias-postumas-bras-cubas.png";
import pupilas from "../_assets/obrasCapa/as-pupilas.png";
import fundamentos from "../_assets/obrasCapa/findamentos-da-fisica.png";
import astronautas from "../_assets/obrasCapa/eram-deuses.png";
import luisBraille from "../_assets/obrasCapa/luis-braille.png";
import defaultImage from "../_assets/obrasCapa/capa-padrao.png";

import bookTest from "../_assets/booksPdf/memoriasPostumasdeBrasCubas.pdf";

const obras = [
	{
		id: 1,
		img: memoriasPostumas,
		titulo: "MEMÓRIAS PÓSTUMAS DE BRÁS CUBAS",
		autor: "Machado de Assis",
		subtitulo: "Transcrição autorizada pela Editora Cultrix",
		arquivo: bookTest,
	},
	{
		id: 2,
		img: pupilas,
		titulo: "AS PUPILAS DO SENHOR REITOR",
		autor: "Júlio Dinis",
		arquivo: bookTest,
	},
	{
		id: 3,
		img: fundamentos,
		titulo: "FUNDAMENTOS DA FÍSICA",
		subtitulo: "Termologia, Óptica Geométrica e Ondas",
		autor: "Vários Autores",
	},
	{
		id: 4,
		img: defaultImage,
		titulo: "COLEÇÃO 4 LIVROS",
		subtitulo:
			"A vaca no telhado | A vaca Rebeca | Confusão no Jardim | O ratinho espertalhão",
		autor: "Autores diversos",
	},
	{
		id: 5,
		img: defaultImage,
		titulo: "CURSO DE PROGRAMAÇÃO PARA DEFICIENTES VISUAIS: COBOL",
		subtitulo: "Volume único",
		autor: "Salamah, Carmen Aparecida",
	},
	{
		id: 6,
		img: astronautas,
		titulo: "ERAM OS DEUSES ASTRONAUTAS?",
		subtitulo: "Volume 1 e Volume 2",
		autor: "Erich Von Dânken",
	},
	{
		id: 7,
		img: luisBraille,
		titulo: "LUÍS BRAILLE - JANELAS PARA OS CEGOS",
		subtitulo: "",
		autor: "J. Alvin Kugelmass",
	},
	{
		id: 8,
		img: defaultImage,
		titulo: "CURSO DE LATIM",
		subtitulo: "Volume Único",
		autor: "",
	},
	{
		id: 9,
		img: defaultImage,
		titulo: "CADERNO DE PRÉ-LEITURA PARA O SISTEMA BRAILLE",
		subtitulo: "Orientação Metodológica",
		autor: "",
	},
	// {
	// 	id: 10,
	// 	img: maquina,
	// 	titulo: "MÁQUINA DE ESCRITA BRAILLE",
	// 	subtitulo: "Tatrapoint",
	// 	autor: "",
	// },
];
export { obras };
