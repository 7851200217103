import { Link } from "react-router-dom";
import logo from "../../_assets/braille-logo.png";
import Navigation from "../Navigation";
const Header = () => {
	return (
		<>
			<nav
				className='navbar navbar-expand-lg'
				style={{ backgroundColor: "#582E16" }}>
				<div className='container'>
					<Link className='navbar-brand' to={"/"}>
						<img
							src={logo}
							height={"90px"}
							alt='Logo Braille de Ontem'
						/>
					</Link>
					<div
						className='d-flex flex-column justify-content-center align-items-center'
						style={{ color: "#ffff" }}>
						<h1>Exposição "Braille de Ontem"</h1>
						<div style={{ width: "80%" }}>
							<p className='lead text-center'>
								Projeto Braille de Ontem: A Preservação,
								Acessibilidade, História e Memória do Patrimônio
								Cultural em Braille de Uberlândia
							</p>
						</div>
					</div>
				</div>
			</nav>
			<Navigation />
		</>
	);
};
export default Header;
