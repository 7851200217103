import braille from "../../_assets/braille.png";
const HistoriaDoBraille = () => {
	return (
		<>
			<div className='row p-5'>
				<h1 className='display-5'>A história do Braille</h1>
				<div className='lead'>
					O Braille é um sistema de escrita e leitura tátil
					desenvolvido para pessoas cegas ou com deficiência visual.
					Ele foi inventado por Louis Braille, um jovem francês que
					perdeu a visão aos três anos de idade após um acidente em
					sua oficina. Em 1824, com apenas 15 anos, Louis Braille
					desenvolveu seu sistema baseado em um código militar chamado
					"escrita noturna", que utilizava pontos em relevo para
					leitura no escuro.
				</div>
				<div className='lead'>
					O sistema Braille consiste em padrões de até seis pontos
					organizados em uma célula retangular, permitindo 63
					combinações diferentes que representam letras, números,
					pontuação e até símbolos musicais. Cada conjunto de pontos
					pode ser lido passando os dedos sobre o relevo.
				</div>
				<div className='lead mb-5'>
					Desde a sua criação, o Braille tem sido fundamental na
					educação e inclusão de pessoas com deficiência visual,
					proporcionando-lhes uma forma eficaz de acessar informações
					e participar plenamente na sociedade. O sistema foi adotado
					internacionalmente e continua a ser amplamente utilizado,
					com adaptações para diversas línguas e novas tecnologias.
				</div>
				<img src={braille} alt='' />
			</div>
		</>
	);
};

export default HistoriaDoBraille;
