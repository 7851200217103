const ItemObra = (props) => {
	const obra = props.obra;
	return (
		<div className='card-obra'>
			<img src={obra.img} alt={obra.titulo} />
			<div className='card-description'>
				<h6 className='lead text-start'>
					<b>{obra.titulo}</b>
				</h6>
				<div className='card-info'>
					<div className='lead'>{obra.subtitulo}</div>
					<div className='lead' style={{ color: "yellow" }}>
						<b>{obra.autor}</b>
					</div>
				</div>
				<a
					target='blank'
					href={obra.arquivo}
					className='btn btn-outline-light'>
					Baixar PDF
				</a>
			</div>
		</div>
	);
};
export default ItemObra;
